import img1 from "./images/vacancy_images/1.webp"
import img2 from "./images/vacancy_images/2.webp"
import img3 from "./images/vacancy_images/3.webp"
import img4 from "./images/vacancy_images/4.webp"
import img5 from "./images/vacancy_images/5.webp"
import img6 from "./images/vacancy_images/6.webp"
import img7 from "./images/vacancy_images/7.webp"
import img8 from "./images/vacancy_images/8.webp"
import img9 from "./images/vacancy_images/9.webp"
import img10 from "./images/vacancy_images/10.webp"
import img11 from "./images/vacancy_images/11.jpg"
import img12 from "./images/vacancy_images/12.webp"
import img13 from "./images/vacancy_images/13.jpg"
import img14 from "./images/vacancy_images/14.webp"
import img15 from "./images/vacancy_images/15.webp"
// import img16 from "./images/vacancy_images/16.webp"
// import img19 from "./images/vacancy_images/19.webp"
import img21 from "./images/vacancy_images/21.webp"
import img22 from "./images/vacancy_images/22.webp"
import img24 from "./images/vacancy_images/24.webp"
import img26 from "./images/vacancy_images/26.webp"
import img27 from "./images/vacancy_images/27.jpg"
import img29 from "./images/vacancy_images/29.webp"
import img30 from "./images/vacancy_images/30.webp"
import img31 from "./images/vacancy_images/31.webp"
import img34 from "./images/vacancy_images/34.webp"
import img40 from "./images/vacancy_images/40.webp"
import img41 from "./images/vacancy_images/41.webp"
import img42 from "./images/vacancy_images/42.webp"
import img44 from "./images/vacancy_images/44.webp"
import img45 from "./images/vacancy_images/45.jpg"
import img46 from "./images/vacancy_images/46.webp"
import img47 from "./images/vacancy_images/47.webp"
import img43 from "./images/vacancy_images/43.webp"
import img48 from "./images/vacancy_images/48.webp"

export const vacancyData = [
    {
        name: "Работник на производстве соков",
        city: "Przysucha (117 км от Варшавы)",
        id: 13,
        img: img13,
        salary: [
            "23 зл/ч",
            "26 зл/час - для водителей погрузчика",
            "от 5000 зл/месяц",
            "Возможна выплата аванса(единоразово)",
            "Зарплата с 10 по 15 число каждого месяца без задержек"
        ],
        factory: "производство соков",
        clothes: "Одежда и инструменты выдаются",
        schedule: [
            "Две смены: 06.00 – 18.00 и 18.00- 6.00",
            "12 часов в день",
            "5-6 дней в неделю",
            "От 280 часов месяц"
        ],
        who: "Мужчины/женщины",
        countPerson: "20 человек",
        housing: [
            "Проживание - 300 зл",
            "Комнаты по 2-3 человека"
        ],
        transport: "До работы можно добраться пешком",
        responsibilities: [
            "Сортировка",
            "Подготовка к отгрузке",
            "Наклеивание стикеров"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Бесплатное проживание",
            "Предоставление рабочей одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "нет особых требований",
        ],
        cityLng: "20.62889",
        cityLat: "51.35858",
        video: "https://www.youtube.com/embed/mAwFgGhmGQs",
        status: "active",
        cityInfo: "Пшису́ха — город в Польше, входит в Мазовецкое воеводство, находится недалеко от города Radom (около 40 км). Имеет статус городско-сельской гмины. Занимает площадь 6,98 км². Население — 6762 человека.",
        factoryInfo: "Hortex - это польская компания, которая производит замороженные продукты и фирменные фруктовые соки. В Польше работает более 1600 сотрудников Hortex. Есть много сезонных работников, которые нанимаются во время обработки фруктов и овощей.",
        houseImages: [
            // "https://i.ibb.co/GT5pHg7/2.jpg",
            // "https://i.ibb.co/XWnCtC7/1.jpg",
            // "https://i.ibb.co/1Td1Pxr/6.jpg",
            // "https://i.ibb.co/gD9yL9n/5.jpg",
            // "https://i.ibb.co/PZjTqXt/4.jpg",
            // "https://i.ibb.co/rbrtYxG/3.jpg"

            "https://i.ibb.co/qNqd1Nz/a0fbf637-9981-4961-b7e4-07177b1ab9ed.jpg",
            "https://i.ibb.co/w4TqCPQ/6dbaf83c-4f21-4519-9bba-604e3294b025.jpg",
            "https://i.ibb.co/3z2R1w6/195cbe3e-2acf-48b2-86ce-ba666c6805f7.jpg",
            "https://i.ibb.co/zrvzDWG/5e53e56c-9bfa-4a05-a14d-2c558de7acd3.jpg",
            "https://i.ibb.co/KqdKhNP/7b7ebade-c89c-4ebd-b215-2ff5cf4c0b94.jpg",
            "https://i.ibb.co/B4ZXHMW/38aab3e5-85ae-4482-8394-7b01a682f583.jpg",
            "https://i.ibb.co/wrNPJbH/76f3a938-8c74-4b13-b139-51f466fd1d4f.jpg",
            "https://i.ibb.co/zrtrVdF/88e2c5d4-85b4-4999-8a9e-b19d94e190bb.jpg"
        ]
    },
    {
        name: "Работник производства систем соединяющих вагоны поездов",
        city: "Miszewko (20 км от Гданьска)",
        id: 52,
        img: img48,
        salary: [
            "25 зл/час",
        ],
        factory: "производство систем соединяющих вагоны поездов",
        clothes: "Рабочая одежда предоставляется",
        schedule: [
            "Две смены: 06.00 - 18.00, 18.00 - 6.00",
            "10-12 часов в день!", 
            "5-6 дней в неделю",
            "от 240 часов в месяц"
        ],
        who: "мужчины",
        countPerson: "10 человек",
        housing: [
            "Жилье - 300 zł/месяц",
            "Комнаты по 2-5 человек"
        ],
        transport: "Транспорт - бесплатный",
        responsibilities: [
            "работа на разных этапах производства соединительных систем для вагонов поездов",
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Предоставление одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "!Возраст: до 55 лет",
            "Обязательное знание русского языка"
        ],
        houseImages: [
            "https://i.ibb.co/j6L6RwR/photo-2024-09-03-15-28-01-2.jpg",
            "https://i.ibb.co/XzT1RN6/photo-2024-09-03-15-28-01-3.jpg",
            "https://i.ibb.co/tp18QMR/photo-2024-09-03-15-28-01.jpg",
            "https://i.ibb.co/Bnbjc8j/photo-2024-09-03-15-28-02-3.jpg",
            "https://i.ibb.co/CHfLwSh/photo-2024-09-03-15-28-02-4.jpg",
            "https://i.ibb.co/5T85LNT/photo-2024-09-03-15-28-02-5.jpg",
            "https://i.ibb.co/NW0WV4T/photo-2024-09-03-15-28-02.jpg"
        ],
        video: "https://www.youtube.com/embed/YhxjsDlt0ww?si=vO1UB8MjDTFVSr-x",
        status: "active",
        cityLng: "20.0",
        cityLat: "52.5167",
        cityInfo: "Мишевко (Miszewko) — деревняв Поморском воеводстве, которое располагается в 20 км от Гданьска.",
        factoryInfo: "Лидер отрасли в разработке, производстве и обслуживании систем соединения поездов для производителей поездов и операторов железных дорог по всему миру.",
    },
    {
        name: "Работник производства окон",
        city: "Kornice (66 км от Katowice)",
        id: 51,
        img: img24,
        salary: [
            "25 зл/час",
        ],
        factory: "производство окон и дверей",
        clothes: "Рабочая одежда предоставляется",
        schedule: [
            "Три смены: 06.00 - 14.00, 14.00 - 22.00, 22.00 - 6.00",
            "8 часов в день!", 
            "5-6 дней в неделю",
            "+ есть возможность оставаться на доп. часы",
            "160 - 240 часов в месяц"
        ],
        who: "мужчины",
        countPerson: "20 человек",
        housing: [
            "Жилье - 300 zł/месяц",
            "Комнаты по 2-5 человек"
        ],
        transport: "До работы можно добраться общественным транспортом",
        responsibilities: [
            "Монтаж окон на производственной линии в соответствии с производственными планами",
            "Контроль качества готовой продукции",
            "Удаление бракованного или поврежденного изделия и уведомление об этом мастера",
            "Защита окон от повреждений при транспортировке",
            "Проверка технического состояния транспортных стеллажей и информирование мастера о выявленных дефектах",
            "Упаковка готовой продукции на транспортные стеллажи в соответствии с заказом",
            "Контроль порядка упаковки продукции и упаковки витрин моделей на соответствующей транспортной станции"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Предоставление одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "!Возраст: до 45 лет",
        ],
        video: "https://www.youtube.com/embed/yTNE9v-ThXk?si=akIgzvlpD56_31FU",
        status: "active",
        cityLng: "18.10749957",
        cityLat: "50.08749965",
        cityInfo: "Корнице — деревня в административном районе гмины Петровице-Вельке, в Рацибужском повяте Силезского воеводства, на юге Польши, недалеко от границы с Чехией. Он расположен примерно в 3 км к востоку от Петровице-Вельке, в 9 км к западу от Рацибужа и в 66 км к западу от столицы региона Катовице.",
        factoryInfo: "Завод является крупнейшим в Европе производителем окон, дверей и ограждений из алюминия и ПВХ. Производственные мощности расположены на юге Польши в Корнице и Водзиславе-Слёнском, где работает около 13 000 сотрудников, а продукция завода уже доступна на 40 зарубежных рынках.",
    },
    {
        name: "Windows production worker",
        city: "Kornice (66 km from Katowice)",
        id: 50,
        img: img22,
        salary: [
            "Salary: 25 PLN/hour",
        ],
        factory: "production of windows and doors",
        clothes: "Clothing is provided",
        schedule: [
            "Three work shifts: 06.00 - 14.00, 14.00 - 22.00, 22.00 - 6.00",
            "8 hours a day!", 
            "5-6 days a week",
            "+ there are additional hours",
            "160 - 240 hours/month"
        ],
        who: "men",
        countPerson: "20 people",
        housing: [
            "Accommodation - 300 zł/month",
            "Rooms for 2-5 people"
        ],
        transport: "You can get to work by public transport",
        responsibilities: [
            "Installation of windows on a dedicated production line in accordance with production plans",
            "Quality control",
            "Removing the defective or damaged product and notifying the foreman about it",
            "Protection of windows against damage during transport",
            "Checking the technical condition of transport racks and informing the foreman about any defects",
            "Packing products on transport racks in accordance with the order",
            "Control of the order of packing products and packing model windows at the appropriate transport station"
        ],
        offers: [
            "Only official employment",
            "State insurance",
            "Assistance in arranging documents for extending stay in Poland (residence card, Work permit, etc.)",
            "Assistance from coordinators at all stages of employment and stay"
        ],
        requirements: [
            "!Age: up to 45 years",
        ],
        video: "https://www.youtube.com/embed/yTNE9v-ThXk?si=akIgzvlpD56_31FU",
        status: "active",
        cityLng: "18.10749957",
        cityLat: "50.08749965",
        cityInfo: "Kornice is a village in the administrative district of Gmina Pietrowice Wielkie, within Racibórz County, Silesian Voivodeship, in southern Poland, close to the Czech border. It lies approximately 3 kilometres (2 mi) east of Pietrowice Wielkie, 9 km (6 mi) west of Racibórz, and 66 km (41 mi) west of the regional capital Katowice. In Kornice located is headquarter and one of windows factory of the Eko-Okna.",
        factoryInfo: "The factory is Europe's largest producer of aluminum and uPVC windows, doors, and shutters. With manufacturing facilities located in southern Poland in Kornice and Wodzisław Śląski it employs approximately 13,000 employees, the factory products already accessible on 40 foreign markets.",
    },
    {
        name: "Разнорабочий",
        city: "Olsztyn",
        id: 49,
        img: img27,
        salary: [
            "23 зл/ч",
            "от 5000 зл/месяц",
            "Возможна выплата аванса(единоразово)",
            "Зарплата с 10 по 15 число каждого месяца без задержек"
        ],
        factory: "производство стекла",
        clothes: "Одежда и инструменты выдаются",
        schedule: [
            "Две смены: 6.00-18.00, 18.00 - 6.00",
            "10-12 часов в день",
            "5-6 дней в неделю (пн-сб)",
            "от 240 часов месяц (может быть и больше, зависит от вашего желания работать)"
        ],
        who: "мужчины/женщины/пары",
        countPerson: "10 человек",
        housing: [
            "Проживание - 300 зл (комнаты по 4 человека)",
            "Есть отдельные комнаты для пар",
            "Комфортные условия."
        ],
        transport: "До работы можно добраться пешком",
        responsibilities: [
            "Контроль качества",
            "Упаковка заказов",
            "Комплектация",
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Предоставление одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "Мужчины/женщины/пары",
        ],
        // houseImages: [
        //     "https://i.ibb.co/QnGjGkS/1000002796.jpg",
        //     "https://i.ibb.co/Vg6xNC1/1000002793.jpg",
        //     "https://i.ibb.co/0B9C8WV/1000002790.jpg",
        //     "https://i.ibb.co/KsW90vT/1000002787.jpg",
        //     "https://i.ibb.co/mzKDtZv/1000002799.jpg"
        // ],
        video: "https://www.youtube.com/embed/mX8iDCDaR_w?si=58uO5QjdvuZ_ZlMT",
        status: "active",
        cityLng: "20.490189",
        cityLat: "53.770226",
        cityInfo: "Ольштын — город в Польше, входит в Силезское воеводство. Расположен примерно в 15 км к юго-востоку от административного центра повята г. Ченстоховы. Занимает площадь 34,25 км². Население — 2499 человек (на 2022 год).",
        factoryInfo: "Ведущий производитель строительного, декоративного и мебельного стекла в Варминско-Мазурском регионе. Для клиентов предлагается широкий ассортиент листового стекла и зеркал.",
    },
    {
        name: "Chicken factory worker",
        city: "Szczecin",
        id: 48,
        img: img43,
        salary: [
            "Salary: 22 PLN/hour",
        ],
        factory: "chicken factory",
        clothes: "Clothing is provided",
        schedule: [
            "Two work shifts 06.00 - 18.00 and 18.00 - 06.00",
            "12-14 hours a day!", 
            "6 days a week (Monday - Saturday)",
            "+ there are additional hours",
            "250-300 hours/month"
        ],
        who: "men or women",
        countPerson: "20 people",
        housing: [
            "Accommodation - 300 zł/month",
        ],
        transport: "You can get to work by public transport (3-5 km)",
        responsibilities: [
           "Bird hanging, bleeding and scalding, removing intestines, neck removal, weighing eviscerated birds, re-hanging for cut up, cutting chicken into pieces "
        ],
        offers: [
            "Only official employment",
            "State insurance",
            "Assistance in arranging documents for extending stay in Poland (residence card, Work permit, etc.)",
            "Assistance from coordinators at all stages of employment and stay"
        ],
        requirements: [
            "!There are no requirements",
        ],
        // video: "https://www.youtube.com/embed/8EATzzOPb68?si=R_qQ0ZuhzHSVzIrd",
        status: "active",
        cityLng: "14.552812",
        cityLat: "53.428543",
        cityInfo: "Szczecin is a beautiful old elegant city and the largest settlement in Western Pomerania of Poland. It is situated on the West Oder, only in a few miles away from the border between Poland and Germany, and about 50 miles south of the Baltic Sea shores. It is a very important local cultural, industrial, transportation, and education center, with a lovely harbor and other natural beauties, as well as plenty of monuments and other tourist attractions. Located in the city center, the Dukes of Pomerania Castle is one of the jewelries of Polish historic architecture. Other landmarks of Szczecin include local parks, cathedrals, restaurants, small and large shopping facilities, historic pubs and modern hotels.",
        factoryInfo: "The factory specialize in the production of meat and poultry meats, and we successfully offer our products on the domestic and foreign markets. Throughout the entire period of its activity, until today, our company has been constantly developing, investing in modern technologies, expanding its product range, increasing production standards and conquering new markets. The quality and safety of production and the wide range of our products have made us one of the largest and most recognized producers of meat and poultry meats in Poland.",
    },
    {
        name: "Работник производства",
        city: "Janów Podlaski (22 км от Бяла-Подляска)",
        id: 47,
        img: img47,
        salary: [
            "23 зл/час",
            "от 5000 зл/месяц", 
            "Возможна выплата аванса(единоразово)",
            "Зарплата с 10 по 15 число каждого месяца без задержек"
        ],
        factory: "производство сухофруктов и батончиков",
        clothes: "Рабочая одежда предоставляется",
        schedule: [
            "Три смены (6.45-14.45, 14.45-22.45, 22.45- 06.45) + есть возможность оставаться на дополнительные часы",
            "Работа по 10-12 часов, 5-6 дней в неделю",
            "200-240 часов в месяц",
            "перерывы по 15 минут каждый 4 часа"
        ],
        who: "мужчины",
        countPerson: "9 человек",
        housing: [
            "Проживание - 300 зл/месяц",
            "Комнаты по 2-4 человек",
            "Жилье оснащено всем необходимым для комфортной жизни: посуда, постельное белье, стиральные мащины, Wi-fi и т.д.",
        ],
        transport: "До работы можно добраться пешком (3 минуты)",
        responsibilities: [ 
            "контроль качества производимой продукции",
            "упаковка готовой продукции",
            "сортировка продукции по вкусу, типу, сорту и т.д.",
            "подготовка к транспортировке", 
            "складирование картонных упаковок"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Предоставление одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"  
        ],
        requirements: [
            "Мужчины до 52 лет",
            "обязательное знание русского языка",
            // "отсутствие маникюра, нарощенных ресниц, какой-либо бижутерии"
        ],
        houseImages: [
            "https://i.ibb.co/MGknJX5/Whats-App-2024-08-28-11-53-04-5d62126a.jpg",
            "https://i.ibb.co/yny8N22/Whats-App-2024-08-28-11-53-04-58b6c047.jpg",
            "https://i.ibb.co/wyqdH8Y/Whats-App-2024-08-28-11-53-04-307e3d72.jpg",
            "https://i.ibb.co/DYztyrD/Whats-App-2024-08-28-11-53-04-06682ad7.jpg",
            "https://i.ibb.co/r2Ly0H2/Whats-App-2024-08-28-11-53-04-a760cf89.jpg",
            "https://i.ibb.co/vm9sRYY/Whats-App-2024-08-28-11-53-04-c4bd6c3c.jpg",
            "https://i.ibb.co/nb7ZjWK/Whats-App-2024-08-28-11-53-05-caf33aaf.jpg"
        ],
        video: "https://www.youtube.com/embed/cpHJxc2ecvc?si=m58fuVGGw9igm0Aw",
        status: "active",
        cityLng: "23.21218",
        cityLat: "52.19398",
        cityInfo: "Янув-Подляски (пол. Janów Podlaski) — деревня в Бяльском повяте Люблинского воеводства Польши. По данным переписи 2011 года, в деревне проживало 2720 человек.Деревня расположена на востоке Польши, на правом берегу реки Чижувки, к югу от реки Западный Буг, вблизи государственной границы с Беларусью, на расстоянии приблизительно 19 километров к северу от города Бяла-Подляска.",
        factoryInfo: "Крупнейший производитель сухофруктов, здоровых батончиков, мюсли, фасованных орехов и других закусок.",
    },
    // {
    //     name: "Работник производства",
    //     city: "Namysłów (65 км от Вроцлава)",
    //     id: 46,
    //     img: img46,
    //     salary: [
    //         "22 зл/час",
    //         "от 5000 зл/месяц", 
    //         "Возможна выплата аванса(единоразово)",
    //         "Зарплата с 10 по 15 число каждого месяца без задержек"
    //     ],
    //     factory: "производство мороженого",
    //     clothes: "Рабочая одежда предоставляется",
    //     schedule: [
    //         "Три смены (6.45-14.45, 14.45-22.45б 22.45- 06.45) + есть возможность оставаться на дополнительные часы",
    //         "Работа по 8-12 часов, 5-6 дней в неделю",
    //         "около 200 часов в месяц",
    //         "перерывы по 15 минут каждый 4 часа"
    //     ],
    //     who: "женщины/мужчины/пары",
    //     countPerson: "20 человек",
    //     housing: [
    //         "Проживание - 300 зл/месяц",
    //         "Комнаты по 2-5 человек",
    //         "Жилье оснащено всем необходимым для комфортной жизни: посуда, постельное белье, стиральные мащины, Wi-fi и т.д.",
    //     ],
    //     transport: "Транспорт бесплатный",
    //     responsibilities: [ 
    //         "контроль качества производимой продукции",
    //         "упаковка готовой продукции",
    //         "сортировка продукции по вкусу, типу, сорту и т.д.",
    //         "подготовка к транспортировке", 
    //         "складирование картонных упаковок"
    //     ],
    //     offers: [
    //         "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
    //         "Предоставление одежды и инструментов",
    //         "Прохождение Техники безопасности и охраны труда(BHP)",
    //         "Государственное страхование",
    //         "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
    //         "Помощь координаторов на всех этапах трудоуствойства и пребывания"  
    //     ],
    //     requirements: [
    //         "женщины/мужчины/пары до 60 лет",
    //         "обязательное знание русского языка",
    //         "!температура в помещении зависит от места работы: от -5 до +20 градусов"
    //     ],
    //     status: "active",
    //     cityLng: "17.72284",
    //     cityLat: "51.07592",
    //     cityInfo: "Намыслув (пол. Namysłów) — город в Польше, входит в Опольское воеводство и находится в 70 км от Вроцлава. Занимает площадь 22,62 км². Население 16 604 человек (на 2004 год).",
    //     factoryInfo: "Это крупнейшая фабрика по производству мороженого и замороженных десертов. В 1947 году Эдмунд Плевицкий открыл деревянный зеленый киоск на углу улиц Пулавской и Мадалиньской в Мокотуве, в помещении бывшего цветочного магазина. Там он продавал овощи, фрукты и пончики собственного приготовления. Через несколько месяцев к нему присоединился сосед, кондитер Казимеж Козелл, который ежедневно доставлял в магазин партии домашнего мороженого. Оно пользовалось такой популярностью, что партнеры купили оборудование и начали производить мороженое в более широких масштабах.",
    // },
    {
        name: "Работник производства",
        city: "Krasnystaw (55 км от Люблина)",
        id: 45,
        img: img45,
        salary: [
            "24 зл/час",
            "от 5000 зл/месяц", 
            "Возможна выплата аванса(единоразово)",
            "Зарплата с 10 по 15 число каждого месяца без задержек"
        ],
        factory: "производство керамики",
        clothes: "Рабочая одежда предоставляется",
        schedule: [
            "Три смены + есть возможность оставаться на дополнительные часы",
            "Работа по 8-12 часов, 5-7 дней в неделю",
            "около 200 часов в месяц",
            "Два перерыва: 15 минут и 10 минут"
        ],
        who: "мужчины",
        countPerson: "30 человек",
        housing: [
            "Проживание - 300 зл/месяц",
            "Комнаты по 2-5 человек",
            "Жилье оснащено всем необходимым для комфортной жизни: посуда, постельное белье, стиральные мащины, Wi-fi и т.д.",
        ],
        transport: "Транспорт бесплатный",
        responsibilities: [ 
            "комплектация продукции",
            "монтаж",
            "упаковка",
            "укладывание готовой продукции на палеты"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Предоставление одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"  
        ],
        requirements: [
            "мужчины до 60 лет",
        ],
        houseImages: [
            "https://i.ibb.co/pfJNZG1/11-2-1.jpg",
            "https://i.ibb.co/K70Jmn1/12-4.jpg",
            "https://i.ibb.co/0GVtJVj/14-4.jpg",
            "https://i.ibb.co/ZKGSKtL/22-3.jpg",
            "https://i.ibb.co/QbHGZTC/88-3.jpg",
            "https://i.ibb.co/KyFQhrL/333-3.jpg",
            "https://i.ibb.co/cJSKkDW/666-3.jpg",
            "https://i.ibb.co/cJy4J9w/777-2-3.jpg"
        ],
        status: "active",
        cityLng: "23.1742",
        cityLat: "50.98464",
        cityInfo: "Красныстав(Krasnystaw) — город в Польше, входит в Люблинское воеводство. Имеет статус городской гмины. Занимает площадь 23,07 км². Население — 19 537 человек.",
        factoryInfo: "Завод, в состав которого входят три больших цеха в городе Опочно (Мазовше, Шлионск и Поможе) занимается производством плиток для стен (также керамических). В своем предложении имеют также оснащение для ванных комнат (унитазы, унитазные крышки, умывальники, шкафчики).",
    },
    // {
    //     name: "Работник склада",
    //     city: "Opoczno (65 км от Радомя, 100 км от Лодзи)",
    //     id: 44,
    //     img: img44,
    //     salary: [
    //         "22 зл/час+премии",
    //         "от 5000 зл/месяц",
    //         "есть возможность получать премии", 
    //         "Возможна выплата аванса(единоразово)",
    //         "Зарплата с 10 по 15 число каждого месяца без задержек"
    //     ],
    //     factory: "производство керамической плитки и санфарфора",
    //     clothes: "Рабочая одежда предоставляется",
    //     schedule: [
    //         "Две смены: 06.00 - 18.00 и 18.00 - 6.00",
    //         "Работа по 12 часов, 5-6 дней в неделю (в субботу работа по 8 часов)", 
    //         "240-280 часов в месяц",
    //         "Два перерыва: 15 минут и 10 минут"
    //     ],
    //     who: "мужчины",
    //     countPerson: "10 человек",
    //     housing: [
    //         "Проживание - 300 зл/месяц",
    //         "Комнаты по 2-5 человек",
    //         "Жилье оснащено всем необходимым для комфортной жизни: посуда, постельное белье, стиральные мащины, Wi-fi и т.д.",
    //         "Ближайшие магазины - 300 - 900 м."
    //     ],
    //     transport: "На работу работники добираются пешком",
    //     responsibilities: [ 
    //         "Работа на складе:",
    //         "- комплектация заказов (плитки): работники переносят коробки с плиткой (до 20 кг) между поддонами, на короткие дистанции, вручную или с помощью специального подъемника или работники переупаковывают поддоны с поврежденной санитарной керамикой",
    //         "- комплектация заказов (умывальники, унитазы): работники забирают заказы со склада с помощью электрической тележки или ручной тележки - рохли",
    //         "- комплектация заказов: работа за лентой, заключающаяся в подаче в ящики необходимых элементов",
    //         "- монтаж сливных бачков: работа за столом, состоящая из установки простых сливных бачков и упаковки готовых комплектов в коробки",
    //         "- подготовка товара к отправке (упаковка, укладывание на поддоны, фольгирование)",
    //     ],
    //     offers: [
    //         "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
    //         "Предоставление одежды и инструментов",
    //         "Прохождение Техники безопасности и охраны труда(BHP)",
    //         "Государственное страхование",
    //         "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
    //         "Помощь координаторов на всех этапах трудоуствойства и пребывания"  
    //     ],
    //     requirements: [
    //         "мужчины до 55 лет",
    //         "обязательное знание русского языка"
    //     ],
    //     status: "active",
    //     cityLng: "20.27827",
    //     cityLat: "51.37569",
    //     cityInfo: "Опо́чно — входит в Лодзинское воеводство, занимает площадь 23,91 км² с населением — 22 613 человек. Среди местных достопримечательностей стоит отметить Региональный Музей в здании замка Казимежа Велькего, а также Залив Опочно.",
    //     factoryInfo: "Завод, в состав которого входят три больших цеха в городе Опочно (Мазовше, Шлионск и Поможе) занимается производством плиток для стен (также керамических). В своем предложении имеют также оснащение для ванных комнат (унитазы, унитазные крышки, умывальники, шкафчики).",
    // },
    {
        name: "Работник склада/работник производства",
        city: "Opoczno (65 км от Радомя, 100 км от Лодзи)",
        id: 44,
        img: img44,
        salary: [
            "22 зл/час+премии",
            "от 5000 зл/месяц", 
            "Возможна выплата аванса(единоразово)",
            "Зарплата с 10 по 15 число каждого месяца без задержек"
        ],
        factory: "производство керамической плитки и санфарфора",
        clothes: "Рабочая одежда предоставляется",
        schedule: [
            "Две смены: 06.00 - 18.00 и 18.00 - 6.00",
            "Работа по 12 часов на складе, 5-6 дней в неделю (в субботу работа по 8 часов)",
            "Работа по 10-12 часов на производстве, 5-7 дней в неделю(выходные и праздничные дни оплачиваются - +2зл к ставке)", 
            "На складе: 240-280 часов в месяц",
            "На производстве: 220-240 часов в месяц",
            "Два перерыва: 15 минут и 10 минут"
        ],
        who: "мужчины/женщины/пары",
        countPerson: "30 человек",
        housing: [
            "Проживание - 300 зл/месяц",
            "Комнаты по 2-5 человек",
            "Жилье оснащено всем необходимым для комфортной жизни: посуда, постельное белье, стиральные мащины, Wi-fi и т.д.",
            "Ближайшие магазины - 300 - 900 м."
        ],
        transport: "На работу работники добираются пешком (ок. 20 мин.)",
        responsibilities: [ 
            "Работа на складе:",
            "- комплектация заказов (плитки): работники переносят коробки с плиткой (до 20 кг) между поддонами, на короткие дистанции, вручную или с помощью специального подъемника или работники переупаковывают поддоны с поврежденной санитарной керамикой",
            "- комплектация заказов (умывальники, унитазы): работники забирают заказы со склада с помощью электрической тележки или ручной тележки - рохли",
            "- комплектация заказов: работа за лентой, заключающаяся в подаче в ящики необходимых элементов",
            "- монтаж сливных бачков: работа за столом, состоящая из установки простых сливных бачков и упаковки готовых комплектов в коробки",
            "- подготовка товара к отправке (упаковка, укладывание на поддоны, фольгирование)",
            "",
            "Работа на производстве:",
            "- 1 отдел - унитазные крышки: обслуживание линии упаковки, сортировка и складывание унитазных крышек в соответствии с образцом, шлифовка краев готового изделия, упаковка с стекирование, поддержание чистоты на рабочем месте.",
            "- 2 отдел - производство керамической плитки: снятие плитки с ленты и укладка в коробки, контроль работы машин и устройств, информирование о всех авариях бригадира, поддержание чистоты на рабочем месте."
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Предоставление одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"  
        ],
        requirements: [
            "мужчины/женщины/пары до 55 лет",
            "обязательное знание русского языка"
        ],
        status: "active",
        cityLng: "20.27827",
        cityLat: "51.37569",
        cityInfo: "Опо́чно — входит в Лодзинское воеводство, занимает площадь 23,91 км² с населением — 22 613 человек. Среди местных достопримечательностей стоит отметить Региональный Музей в здании замка Казимежа Велькего, а также Залив Опочно.",
        factoryInfo: "Завод, в состав которого входят три больших цеха в городе Опочно (Мазовше, Шлионск и Поможе) занимается производством плиток для стен (также керамических). В своем предложении имеют также оснащение для ванных комнат (унитазы, унитазные крышки, умывальники, шкафчики).",
    },
    {
        name: "Fish processing worker",
        city: "Białogard (40 km from Kołobrzeg)",
        id: 42,
        img: img41,
        salary: [
            "Salary: 22 PLN/hour",
        ],
        factory: "fish factory",
        clothes: "Clothing is provided",
        schedule: [
            "Two work shifts 06.00 - 15.30 and 16.00 - 2.00",
            "6 days a week (Monday - Saturday)",
            "+ there are additional hours",
            "200-220 hours/month"
        ],
        who: "men or women",
        countPerson: "13 people",
        housing: [
            "Accommodation - 300 zł/month (two rooms for 4 people, one  - for 2 people, one - for 3 people)",
        ],
        transport: "You can get to work on foot (about 1 km)",
        responsibilities: [
           "Unloading of raw materials, decapitation, trimming of ribs and fats, production of cleaned meat and minced meat, deboned, skinning, packaging etc."
        ],
        offers: [
            "Temperature at the factory: 12-16 degrees",
            "Only official employment",
            "State insurance",
            "Assistance in arranging documents for extending stay in Poland (residence card, Work permit, etc.)",
            "Assistance from coordinators at all stages of employment and stay"
        ],
        requirements: [
            "!Candidates must speak English at a communicative level",
        ],
        video: "https://www.youtube.com/embed/8EATzzOPb68?si=R_qQ0ZuhzHSVzIrd",
        status: "active",
        cityLng: "15.98751",
        cityLat: "54.00696",
        cityInfo: "Białogard (40 km from Kołobrzeg or 230 km from Gdańsk) is a historic town in Middle Pomerania, northwestern Poland, with 23,614 inhabitants as of December 2021.[3] The capital of Białogard County in the West Pomeranian Voivodeship. It is the most important railroad junction of Middle Pomerania, which links Kołobrzeg with Piła and Gdańsk with Stargard.",
        factoryInfo: "",
    },
    {
        name: "Sushi production worker",
        city: "Poznań",
        id: 43,
        img: img42,
        salary: [
            "Salary: 21 PLN/hour",
        ],
        factory: "sushi factory",
        clothes: "Clothing is provided",
        schedule: [
            "Two work shifts: 06.00 - 16.00 and 16.00 - 2.00",
            "6 days a week (Monday - Saturday)",
            "+ there are additional hours",
            "200-250 hours/month"
        ],
        who: "men or women",
        countPerson: "10 people",
        housing: [
            "Accommodation - 300 zł/month",
        ],
        transport: "Transport - free",
        responsibilities: [
           "Rolling sushi rolls, receiving goods for production, preparing products for production, cleaning job position after work"
        ],
        offers: [
            "Temperature at the factory: 8-12 degrees",
            "Only official employment",
            "State insurance",
            "Assistance in arranging documents for extending stay in Poland (residence card, Work permit, etc.)",
            "Assistance from coordinators at all stages of employment and stay"
        ],
        requirements: [
            "No requirements",
        ],
        video: "https://www.youtube.com/embed/bmTF5Ncuh6A?si=MzEjP3rx2fGOO4j4",
        status: "active",
        cityLng: "16.931992",
        cityLat: "52.409538",
        cityInfo: "Poznań is a city on the River Warta in west-central Poland, within the Greater Poland region. The city is an important cultural and business centre, and one of Poland's most populous regions with many regional customs such as Saint John's Fair (Jarmark Świętojański), traditional Saint Martin's croissants and a local dialect. Among its most important heritage sites are the Renaissance Old Town, Town Hall and Gothic Cathedral.",
        factoryInfo: "",
    },
    {
        name: "Разнорабочий",
        city: "Barczewo (20 км от Ольштына)",
        id: 41,
        img: img34,
        salary: [
            "23 зл/ч",
            "от 5000 зл/месяц",
            "Возможна выплата аванса(единоразово)",
            "Зарплата с 10 по 15 число каждого месяца без задержек"
        ],
        factory: "производство мебели",
        clothes: "Одежда и инструменты выдаются",
        schedule: [
            "Одна смена: 7.00-16.00",
            "9 часов в день + дополнительные часы",
            "5-6 дней в неделю (пн-сб)",
            "В субботу работа по 6-8 часов",
            "от 240 часов месяц (может быть и больше, зависит от вашего желания работать)"
        ],
        who: "мужчины",
        countPerson: "6 человек",
        housing: [
            "Проживание - 300 зл (комнаты по 4 человека)",
            "Комфортные условия."
        ],
        transport: "Транспорт бесплатный",
        responsibilities: [
            "Контроль качества",
            "Упаковка заказов",
            "Комплектация",
            "По Вашей просьбе можем выслать видео процесса работы"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "!Бесплатный обед",
            "Бесплатное проживание и транспорт",
            "Предоставление одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "Мужчины/женщины/пары до 60 лет",
        ],
        // houseImages: [
        //     "https://i.ibb.co/QnGjGkS/1000002796.jpg",
        //     "https://i.ibb.co/Vg6xNC1/1000002793.jpg",
        //     "https://i.ibb.co/0B9C8WV/1000002790.jpg",
        //     "https://i.ibb.co/KsW90vT/1000002787.jpg",
        //     "https://i.ibb.co/mzKDtZv/1000002799.jpg"
        // ],
        status: "active",
        cityLng: "20.69112",
        cityLat: "53.83055",
        cityInfo: "Барчево — город в Польше, входит в Варминьско-Мазурское воеводство, находится в 20 км от Ольштына. Занимает площадь 4,58 км². Население — 7472 человека. Территория города богата лесами многочисленными природными и ландшафтными ценностями, способствующими развитию туризма, в том числе агротуризма.",
        factoryInfo: "Одна из самых крупных мебельных фабрик в Польше, производящая мягкую мебель (диваны, кресла, пуфы и т.д.) и не только.",
    },
    {
        name: "Разнорабочий",
        city: "Kosów Lacki (110 км от Варшавы)",
        id: 40,
        img: img40,
        salary: [
            "21 зл/ч",
            "от 5000 зл/месяц",
            "Возможна выплата аванса(единоразово)",
            "Зарплата с 10 по 15 число каждого месяца без задержек"
        ],
        factory: "производство масла",
        clothes: "Одежда и инструменты выдаются",
        schedule: [
            "Две смены: 6.00-18.00, 18.00-6.00",
            "12 часов в день",
            "5-6 дней в неделю (пн-сб)",
            "от 240 часов месяц (может быть и больше, зависит от вашего желания работать)"
        ],
        who: "мужчины/женщины/пары",
        countPerson: "10 человек",
        housing: [
            "Проживание - 300 зл (комнаты по 2 человека)",
            "Комфортные условия."
        ],
        transport: "До работы можно добраться пешком (1 км)",
        responsibilities: [
            "Контроль качества",
            "Упаковка заказов",
            "Комплектация",
            "По Вашей просьбе можем выслать видео процесса работы"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Предоставление одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "Мужчины/женщины/пары до 60 лет",
        ],
        houseImages: [
            "https://i.ibb.co/3vKWL9d/Whats-App-2024-01-04-15-43-12-4c1087e6.jpg",
            "https://i.ibb.co/4txYzxx/Whats-App-2024-01-04-15-43-12-56c9b630.jpg",
            "https://i.ibb.co/t2GJcYQ/Whats-App-2024-01-04-15-43-12-571b93e3.jpg",
            "https://i.ibb.co/WxdvcHx/Whats-App-2024-01-04-15-43-12-5519b387.jpg",
            "https://i.ibb.co/DpLyNfS/Whats-App-2024-01-04-15-43-12-ceb6e507.jpg"
        ],
        // video: "https://www.youtube.com/embed/GaXwE3SWaBA?si=Y1H2rUxPm5lSrZOv",
        status: "active",
        cityLng: "22.14700300",
        cityLat: "52.59602800",
        cityInfo: "Косув-Ляцки (110 км от Варшавы и 25 км от Соколув-Подляски)— город в Польше, входит в Мазовецкое воеводство, Соколувский повят. Имеет статус городско-сельской гмины. Занимает площадь 11,57 км². Население — 2152 человек.",
        factoryInfo: "",
    },
    // {
    //     name: "Разнорабочий",
    //     city: "Бытув (90 км от Гданьска)",
    //     id: 38,
    //     img: img6,
    //     salary: [
    //         "21 зл/ч",
    //         "от 5000 зл/месяц",
    //         "Возможна выплата аванса(единоразово)",
    //         "Зарплата с 10 по 15 число каждого месяца без задержек"
    //     ],
    //     factory: "производство окон, дверей, ролет",
    //     clothes: "Одежда и инструменты выдаются",
    //     schedule: [
    //         "Две смены: 6.00-18.00, 18.00-6.00",
    //         "10-12 часов в день",
    //         "5-6 дней в неделю (пн-сб)",
    //         "~ 240 часов месяц (может быть и больше, зависит от вашего желания работать)"
    //     ],
    //     who: "пары/женщины/мужчины",
    //     countPerson: "20 человек",
    //     housing: [
    //         "Проживание - 300 зл",
    //         "Комфортные условия."
    //     ],
    //     transport: "До работы можно добраться пешком",
    //     responsibilities: [
    //         "Участие в разных производственных процессах",
    //         "Контроль качества",
    //         "Упаковка заказов",
    //         "Комплектация"
    //     ],
    //     offers: [
    //         "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
    //         "Предоставление одежды и инструментов",
    //         "Прохождение Техники безопасности и охраны труда(BHP)",
    //         "Государственное страхование",
    //         "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
    //         "Помощь координаторов на всех этапах трудоуствойства и пребывания"
    //     ],
    //     requirements: [
    //         "Пары/женщины/мужчины до 60 лет",
    //     ],
    //     houseImages: [
    //         "https://i.ibb.co/hLCYq5F/photo-2023-11-29-11-03-09.jpg",
    //         "https://i.ibb.co/86Hfyjz/photo-2023-11-29-11-03-19.jpg",
    //         "https://i.ibb.co/mtFNBkg/photo-2023-11-29-11-03-36.jpg",
    //         "https://i.ibb.co/nMqj31k/photo-2023-11-29-11-03-41.jpg",
    //         "https://i.ibb.co/R6dGsp9/photo-2023-11-29-11-04-00.jpg",
    //         "https://i.ibb.co/0JZKd9C/photo-2023-11-29-17-21-38.jpg",
    //         "https://i.ibb.co/cYbz1W4/photo-2023-11-29-11-02-31.jpg",
    //         "https://i.ibb.co/jDj1ZK7/photo-2023-11-29-11-02-55.jpg",
    //         "https://i.ibb.co/rZCzkgs/photo-2023-11-29-11-03-00.jpg",
    //     ],
    //     video: "https://www.youtube.com/embed/GaXwE3SWaBA?si=Y1H2rUxPm5lSrZOv",
    //     status: "active",
    //     cityLng: "17.49187",
    //     cityLat: "54.17057",
    //     cityInfo: "Бы́тув (Bytów - 90 км от Гданьска) — город в Польше, входит в Поморское воеводство. Занимает площадь 8,72 км². Население — 16 888 человек.",
    //     factoryInfo: "Одна из крупнейших фирм производящих окна, двери, роллеты, жалюзи и т.д. Действует на рынке около 40 лет и экспортирует свою продукцию более чем в 40 стран.",
    // },
    {
        name: "Мойка на фабрике мясных полуфабрикатов",
        city: "Starachowice (45 км от Радома)",
        id: 39,
        img: img30,
        salary: [
            "22.50 зл/ч",
            "от 5000 зл/месяц",
            "Возможна выплата аванса (единоразово)",
            "Зарплата от 10 по 15 число каждого месяца без задержек"
        ],
        who: "Мужчины/женщины/пары",
        countPerson: "10 человек",
        factory: "фабрика мясных полуфабрикатов",
        schedule: [
            "1 смена: 18.00/20.00 - 6.00/8.00",
            "есть также возможность работать в дневную смену, но реже",
            "5-6 дней в неделю",
            "12 часов в день",
        ],
        housing: [
            "Проживание - 600 зл",
            "Комфортные условия",
        ],
        responsibilities: [
            "Уборка помещения (ниже есть видео с места работы)",
        ],
        offers: [
            "Только официальное трудоустройство по Umowie zlecenia",
            "Бесплатные обеды",
            "Предоставление рабочей одежды и инструментов",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "Мужчины/женщины/пары до 60 лет",
        ],
        houseImages: [
            // "https://i.ibb.co/gvJxgDX/71e3d232-9443-466f-8876-457981d49cb6.jpg",
            // "https://i.ibb.co/KV6RbYQ/3ff8fd3e-a0be-4179-b4d8-12c56147b780.jpg",
            // "https://i.ibb.co/qg8Vby6/15e5173f-7a81-4b8e-8833-7f2d20596b38.jpg",
            // "https://i.ibb.co/jzK8z3j/88be7b23-3189-4324-ac73-39da403f10a3.jpg",
            // "https://i.ibb.co/PFfgW3X/e0789190-ae51-452c-9b7b-f6942bdf67f6.jpg",
            // "https://i.ibb.co/VvZTKn7/b20e38ee-f4cb-4979-96b7-31370729e9b2.jpg"
        ],
        transport: "Бесплатный транспорт",
        video: "https://www.youtube.com/embed/YJb3YKpzcsM?si=LJWD0pnHmpA4d-ka",
        status: "active",
        cityLng: "21.07126",  
        cityLat: "51.0374",
        cityInfo: "Стараховице занимает площадь 31,85 км². Население — 55 126 человек.",
        factoryInfo: " "
    },
    {
        name: "Упаковщик печенья и сладостей",
        city: "Radzymin(17 км от Варшавы)",
        id: 2,
        img: img2,
        salary: [
            "22 зл/ч",
            "От 5000 зл/месяц",
            "Возможность получения премии за хорошую работу",
            "Возможна выплата аванса(единоразово)",
            "Зарплата с 10 по 15 число каждого месяца без задержек"
        ],
        factory: "производство пищевых продуктов, печенья и сладостей",
        clothes: "Одежда и инструменты выдаются",
        schedule: [
            "Две смены: 06.00 – 18.00 и 18.00- 6.00",
            "12 часов в день",
            "От 280 часов месяц"
        ],
        who: "Женщины/мужчины/пары",
        countPerson: "15 человек",
        housing: [
            "Проживание - 300 зл",
            "Предоставляем отдельные комнаты для пар",
            "Доплата за свое жилье - 400 зл"
        ],
        responsibilities: [
            "Упаковка готовой продукции",
            "Контроль качества",
            "Работа на производственных линиях и складах"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Транспорт бесплатный",
            "Предоставление рабочей одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "Только женщины/мужчины до 55 лет",
            "Женщинам запрещается на производстве иметь нарощенные ресницы, маникюр, любые украшения и бижутерию"
        ],
        houseImages: [
            "https://i.ibb.co/SxNzBmb/IMG-8470.jpg",
            "https://i.ibb.co/vdYDzyq/IMG-8458.jpg",
            "https://i.ibb.co/z2p3RJq/IMG-8461.jpg",
            "https://i.ibb.co/m5tJ7RW/IMG-8464.jpg",
            "https://i.ibb.co/xmyFmx5/IMG-8465.jpg",
            "https://i.ibb.co/bXKh9j1/IMG-8467.jpg",
            "https://i.ibb.co/Y821CYZ/IMG-8468.jpg",
            "https://i.ibb.co/dfZXjSC/IMG-8472.jpg",
            "https://i.ibb.co/Yy39gzB/IMG-8474.jpg",
            "https://i.ibb.co/3zschX9/IMG-8476.jpg",
            "https://i.ibb.co/zxwfzMN/IMG-8478.jpg"
        ],
        transport: "Транспорт бесплатный",
        cityLng: "21.18415",
        cityLat: "52.41592",
        video: "https://www.youtube.com/embed/yeCHJrK-bMo",
        status: "active",
        cityInfo: "Радзымин — город в Польше, который находится в Мазовецком воеводстве. Имеет статус городско-сельской гмины. Занимает площадь 23,32 км². Население составляет 12644 человек. Расположен недалеко от столицы Польши - Варшавы (около 35км).",
        factoryInfo: "Производитель сладостей и печенья TAGO - одно из крупнейших предприятий по производству кондитерских изделий в Польше. За 50 лет своей деятельности TAGO завоевал признание как в Польше, так и за рубежом. Предприятие предлагает широкий ассортимент продукции: слоеное печенье, имбирное печенье, вафли и вафельные трубочки, песочное и бисквитное печенье мини-рулеты и булочки, фруктовое печенье и бисквиты, ламинированное печенье, овсяное печенье и изделия в шоколадной глазури."
    },
    {
        name: "Работник на производство медицинских инструментов",
        city: "Гродзиск-Мазовецки (30 км от Варшавы)",
        id: 31,
        img: img31,
        salary: [
            "21-23 зл/ч",
            "от 5000 зл/месяц",
            "Возможна выплата аванса (единоразово)",
            "Зарплата от 10 по 15 число каждого месяца без задержек"
        ],
        who: "Мужчины/женщины/пары",
        countPerson: "5 человек",
        factory: "производство медицинских инструментов",
        schedule: [
            "2 смены: 06.00 – 18.00, 18.00 - 6.00",
            "5-6 дней в неделю",
            "12 часов в день",
        ],
        housing: [
            "Проживание - 400 зл",
            "Комфортные условия, по 2-4 человека в комнате (ниже есть фото жилья)."
        ],
        responsibilities: [
            "обслуживание производственного оборудования",
            "контроль качества"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Предоставление рабочей одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "Мужчины/женщины/пары",
            "Понимание польского"
        ],
        houseImages: [
            "https://i.ibb.co/H4GpLZj/4.jpg",
            "https://i.ibb.co/d2cCsmm/9-1.jpg",
            "https://i.ibb.co/y54r4gK/2-1.jpg",
            "https://i.ibb.co/PczJftt/3.jpg",
            "https://i.ibb.co/db2Q85F/14.jpg",
            "https://i.ibb.co/XCQqXmg/17.jpg"
        ],
        transport: "До работы можно добраться пешком (150 м)",
        status: "active",
        cityLng: "20.5887082",  
        cityLat: "52.093974",
        cityInfo: "Гродзиск-Мазовецки (Grodzisk Mazowiecki) — город в Польше, входит в Мазовецкое воеводство. Имеет статус городско-сельской гмины. Занимает площадь 13,19 км². Население — 26 881 человек.",
        factoryInfo: "Acti-Med разрабатывает, производит и реализует высококачественную медицинскую продукцию для медицинских технологий и фармацевтической промышленности (в основном канюли разного назначения: для шприцов, катетеров и т.д.)"
    },
    {
        name: "Работник на переработку фруктов и овощей",
        city: "Skierniewice (80 км от Варшавы)",
        id: 12,
        img: img12,
        salary: [
            "21 зл/ч",
            "от 5000 зл/месяц",
            "Возможна выплата аванса(единоразово)",
            "Зарплата с 10 по 15 число каждого месяца без задержек"
        ],
        factory: "переработка фруктов и овощей",
        clothes: "Одежда и инструменты выдаются",
        schedule: [
            "Две смены: 06.00 – 18.00 и 18.00- 6.00",
            "! Очень частые перерывы",
            "12 часов в день",
            "От 280 часов месяц"
        ],
        who: "Мужчины/женщины/пары",
        countPerson: "10 человек",
        housing: [
            "Проживание - 300 зл",
            "Комфортные условия",
            "Отдельные комнаты для пар",
            "Комнаты по 2,3 человека",
        ],
        transport: "До работы можно добраться пешком",
        responsibilities: [
            "Сортировка при производственной ленте",
            "Контроль качества",
            "Упаковка продукции",
            "Складывание коробок"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Возможность проживать с детьми за доп. плату",
            "До работы можно добраться пешком",
            "!Бесплатные теплые обеды",
            "Предоставление теплой рабочей одежды и инструментов(на производстве температура 5°C)",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "Мужчины/женщины/пары",
        ],
        houseImages: [
            // "https://i.ibb.co/9bzB8Xp/386129-40.jpg",
            // "https://i.ibb.co/wzD7d5g/386127-40.jpg",
            // "https://i.ibb.co/dLKFhm2/386125-40.jpg",
            // "https://i.ibb.co/XJPP6hW/386128-40.jpg",
            // "https://i.ibb.co/yN4ZQq9/386124-40.jpg",
            // "https://i.ibb.co/wBqSKxb/386126-40.jpg",
            // "https://i.ibb.co/gwj626b/386123-40.jpg",

            "https://i.ibb.co/Jd0pM4b/3.jpg",
            "https://i.ibb.co/QfXDVrt/11.jpg",
            "https://i.ibb.co/h8SP4w3/10.jpg",
            "https://i.ibb.co/BfJv0mk/9.jpg",
            "https://i.ibb.co/PcFTz82/8.jpg"

            // "https://i.ibb.co/VvcCS0y/viber-2022-06-02-13-08-44-505.jpg",
            // "https://i.ibb.co/x2427nd/viber-2022-06-02-13-08-44-748.jpg",
            // "https://i.ibb.co/fDXCzZK/viber-2022-06-02-13-08-44-960.jpg",
            // "https://i.ibb.co/D5pg00F/viber-2021-11-02-10-23-11-355.jpg",
            // "https://i.ibb.co/KxTx6xx/viber-2021-11-02-10-23-12-679.jpg",
            // "https://i.ibb.co/9bNmR4p/viber-2021-11-02-10-23-14-374.jpg",
            // "https://i.ibb.co/mCZ8C70/viber-2021-11-02-10-23-15-020.jpg"

        ],
        cityLng: "20.1583303",
        cityLat: "51.9547169",
        video: "https://www.youtube.com/embed/mAwFgGhmGQs",
        status: "active",
        cityInfo: "Скерневице — город в Лодзинском воеводстве расположен на полпути между Варшавой и Лодзи. По данным на 30 июня 2004 г., население составляет 49 142 жителей. Площадь города — 32,6 км². Скерневице - город, внесенный в список памятников архитектуры. Гордость Скерневице – великолепный дворец архиепископов гнезненских, а также часовня, построенная в 1761-1765 гг. Одной из крупнейших достопримечательностей является деревянная усадьба 18 века, в которой жила Констанция Гладковска - муза Фридерика Шопена.",
        factoryInfo: "Polski Ogród Hortex - фирма которая занимается переработкой фруктов и овощей и производит фруктовые и овощные смеси, соки, грибные смеси, фруктовые и овощные пюре, концентраты и т.д. Компания насчитывает более 700 сотрудников и имеет 3 производственные площадки."
    },
    {
        name: "Работник мебельной фабрики",
        city: "Wysoka (65 км от Bydgoszcza)",
        id: 4,
        img: img4,
        salary: [
            "22 зл/ч",
            "от 5000 зл/месяц",
            "Возможна выплата аванса (единоразово)",
            "Зарплата от 10 по 15 число каждого месяца без задержек"
        ],
        factory: "производство мебели",
        who: "Мужчины",
        countPerson: "5 мужчин",
        schedule: [
            "Две смены: 06.00 – 18.00 и 18.00- 6.00",
            "10-12 часов в день",
            "5-6 дней в неделю",
            "От 280 часов месяц"
        ],
        housing: [
            "Проживание - 300 зл (комнаты по 2 человека)",
            "Комфортные условия."
        ],
        responsibilities: [
            "Производство мебельных конструкций и мебели(кухонная мебель, шкафы и др.)",
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Предоставление рабочей одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "Только мужчины",
        ],
        transport: "До работы можно добраться пешком (5 мин)",
        cityLng: "17.08353",
        cityLat: "53.18091",
        video: "https://www.youtube.com/embed/WZRECFmOj5k",
        status: "active",
        cityInfo: "Высочка - это деревня в административном округе Гмина Высока, Великопольское воеводство, в западно-центральной Польше. Население составляет около 300 человек и расположена она примерно в 100 км к северу от региональной столицы Познани, которая является важным экономическим, научным, культурным и туристическим центром западной Польши.",
        factoryInfo: "Hjort Knudsen - проектирует, производит и продает мебель клиентам по всей Европе. Компания имеет репутацию в мебельной промышленности как лидера инноваций. Hjort Knudsen производит множество видов продукции, от матрасов до диванов.",
        houseImages: [
            "https://i.ibb.co/qg5xSfx/image0000-6.jpg",
            "https://i.ibb.co/HTtsyLj/image0000-5.jpg",
            "https://i.ibb.co/9Zf9JSK/image0000-4.jpg",
            "https://i.ibb.co/VxsbwX2/image0000-3.jpg",
            "https://i.ibb.co/TYjkrZ2/image0000-2.jpg",
            "https://i.ibb.co/3yNj2S4/image0000-1.jpg",
            "https://i.ibb.co/9ZmXtjN/image0000.jpg"
        ]
    },
    {
        name: "Работник на шоколадную фабрику",
        city: "Pruszków (15 км от Варшавы)",
        id: 29,
        img: img29,
        salary: [
            "21-22 зл/ч",
            "от 5000 зл/месяц",
            "Возможна выплата аванса (единоразово)",
            "Зарплата от 10 по 15 число каждого месяца без задержек"
        ],
        who: "Мужчины/женщины/пары",
        countPerson: "20 человек",
        factory: "упаковка шоколадной продукции",
        schedule: [
            "2 смены: 06.00 – 18.00, 18.00 - 6.00",
            "5-6 дней в неделю",
            "12 часов в день",
        ],
        housing: [
            "Бесплатное проживание",
            "Комфортные условия."
        ],
        responsibilities: [
            "работа на производственной линии (работа сидячая, но мануальная)",
            "упаковка готовой продукции"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Бесплатное проживание",
            "Предоставление рабочей одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "Мужчины/женщины/пары",
            "Отсутствие при выходе на работу нарощенных ресниц, ногтей, украшений",
        ],
        houseImages: [
            // "https://i.ibb.co/tM1w2WT/IMG-2596.jpg",
            // "https://i.ibb.co/G7hrqgC/IMG-2597.jpg",
            // "https://i.ibb.co/XZZHrTc/IMG-2598.jpg",
            // "https://i.ibb.co/34KjRW6/IMG-2600.jpg",
            // "https://i.ibb.co/6rhDfnY/IMG-2601.jpg",
            // "https://i.ibb.co/PrFMQpQ/IMG-2602.jpg",
            // "https://i.ibb.co/pPznfCV/IMG-2604.jpg"
            "https://i.ibb.co/3p72XdW/image-1.webp",
            "https://i.ibb.co/PZ96WtD/image-2.webp",
            "https://i.ibb.co/52XbhYr/image-3.webp",
            "https://i.ibb.co/BZsJ6wq/image-4.webp",
            "https://i.ibb.co/sjXw5J9/image-5.webp",
            "https://i.ibb.co/2gZz4fx/image-6.webp",
            "https://i.ibb.co/S7VcpwF/image-7.webp",
            "https://i.ibb.co/8NJVthm/image-8.webp",
            "https://i.ibb.co/frVbzmH/image.webp"
        ],
        transport: "Самостоятельный доезд до работы (такси или автобус ~ 7 минут езды)",
        status: "active",
        cityLng: "20.81214",  
        cityLat: "52.17072",
        cityInfo: "Пру́шкув - город в Польше, входит в Мазовецкое воеводство и находится в 15 км от Варшавы. Население — 61 784 человек. Является пятым по населению городом в Мазовецком воеводстве. ",
        factoryInfo: "Шоколадная фабрика Carletti производит широкий ассортимент шоколадных изделий в категориях конфет, батончиков, пралине, драже, шоколадных палочек и т.д. Фабрика предлагает изделия из молочного и темного шоколада отличного качества в широком ассортименте форм и начинок. "
    },
    {
        name: "Работник аккумуляторного завода",
        city: "Poznań",
        id: 3,
        img: img3,
        salary: [
            "22 зл/час",
            "отдел продукции плит - 23 зл/час",
            "оператор - 25 зл/час",
            "от 5400 зл/месяц",
            "Возможна выплата аванса (единоразово)",
            "Зарплата с 10 по 15 число каждого месяца без задержек"
        ],
        factory: "производство аккумуляторов",
        who: "Мужчины",
        countPerson: "Только мужчины",
        schedule: [
            "Две смены: 06.00 – 18.00 и 18.00- 6.00",
            "10-12 часов в день",
            "5-6 дней в неделю",
            "От 280 часов месяц"
        ],
        housing: [
            "Проживание в Apart Hostel - 200/300 зл",
            "В комнате проживает по 4 человека",
            "Комфортные условия. В каждой комнате есть кухня, ванна."
        ],
        responsibilities: [
            "Производство аккумуляторов и их составляющих",
            "Работа на складах"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Бесплатные обеды",
            "Предоставление рабочей одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "Только мужчины",
        ],
        transport: "Транспорт до работы за свой счет общественным транспортом (добираться около 40 минут)",
        cityLng: "16.931992",
        cityLat: "52.409538",
        video: "https://www.youtube.com/embed/46SEqeCe5_8",
        status: "active",
        cityInfo: "Город Познань когда-то был столицей Польши и до сих пор сохраняет статус столицы Великопольского воеводств. Познань лежит на полпути между Берлином и Варшавой. Во время Второй мировой войны город сильно пострадал, однако впоследствии был восстановлен в прежнем великолепии. Огромная площадь Старого города, Старый Ринек – одна из самых красивых площадей Европы. Здесь Вы увидите множество поразительных исторических достопримечательностей и ресторанов, и здесь же находится центр ночной жизни города. Кафедральный собор Св. Петра и Павла является одним из старейших соборов Польши.",
        factoryInfo: "Exide Technologies занимается производством аккумуляторов для машин, является лидером в данной отрасли по качеству и производительности, способствует достижению результатов для клиентов и меняют жизнь людей к лучшему. Exide выступает за безопасность, инновации и партнерство, обеспечивая при этом достойные условия труда для своих сотрудников.",
        houseImages: [
            "https://i.ibb.co/G56fDZn/2.jpg",
            "https://i.ibb.co/34P4z8R/79.jpg",
            "https://i.ibb.co/PNL8HyQ/11.jpg",
            "https://i.ibb.co/dWsdJMh/5.jpg",
            "https://i.ibb.co/ZLgsxQK/4.jpg",
            "https://i.ibb.co/5MQvW5Y/3.jpg"
        ]
    },
    // {
    //     name: "Работник на стекольное производство",
    //     city: "Poznań",
    //     id: 27,
    //     img: img27,
    //     salary: [
    //         "17.50, 20 зл/ч",
    //         "Дневная смена - 17.50, ночная - 20",
    //         "+ премия за посещаемость ок. 150 зл",
    //         "от 3500 зл/месяц",
    //         "Возможна выплата аванса (единоразово)",
    //         "Зарплата от 10 по 15 число каждого месяца без задержек"
    //     ],
    //     who: "Мужчины",
    //     countPerson: "2 мужчин",
    //     factory: "производство стеклопакетов",
    //     schedule: [
    //         "три смены: 06.00 – 14.00, 14.00 - 22.00, 22:00 - 6:00",
    //         "5-6 дней в неделю",
    //         "8 часов в день",
    //     ],
    //     housing: [
    //         "Бесплатное проживание в Apart Hostel",
    //         "Комфортные условия."
    //     ],
    //     responsibilities: [
    //         "Производство изоляционного стекла",
    //         "Нарезка, мойка, сортировка стекла",
    //         "Изготовление рамок",
    //         "Подготовка к отправке т.д."
    //     ],
    //     offers: [
    //         "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
    //         "Бесплатное проживание",
    //         "Предоставление рабочей одежды и инструментов",
    //         "Помощь в получении прав UDT",
    //         "Прохождение Техники безопасности и охраны труда(BHP)",
    //         "Государственное страхование",
    //         "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
    //         "Помощь координаторов на всех этапах трудоуствойства и пребывания"
    //     ],
    //     requirements: [
    //         "Мужчины в возрасте от 20 до 45 лет",
    //     ],
    //     houseImages: [
    //         "https://i.ibb.co/G56fDZn/2.jpg",
    //         "https://i.ibb.co/34P4z8R/79.jpg",
    //         "https://i.ibb.co/PNL8HyQ/11.jpg",
    //         "https://i.ibb.co/dWsdJMh/5.jpg",
    //         "https://i.ibb.co/ZLgsxQK/4.jpg",
    //         "https://i.ibb.co/5MQvW5Y/3.jpg"
    //     ],
    //     transport: "Доезд общественным транспортом за свой счет",
    //     video: "https://www.youtube.com/embed/Z8-aocgVLCQ",
    //     status: "active",
    //     cityLng: "16.931992",
    //     cityLat: "52.409538",
    //     cityInfo: "Город Познань когда-то был столицей Польши и до сих пор сохраняет статус столицы Великопольского воеводств. Познань лежит на полпути между Берлином и Варшавой. Во время Второй мировой войны город сильно пострадал, однако впоследствии был восстановлен в прежнем великолепии. Огромная площадь Старого города, Старый Ринек – одна из самых красивых площадей Европы. Здесь Вы увидите множество поразительных исторических достопримечательностей и ресторанов, и здесь же находится центр ночной жизни города. Кафедральный собор Св. Петра и Павла является одним из старейших соборов Польши.",
    //     factoryInfo: "Glassolutions является частью группы Saint-Gobain и благодаря многолетнему опыту является ведущим поставщиком решений высочайшего качества и самых инновационных изделий из стекла для разного вида помещений, строительства, окон и дверей."
    // },
    {
        name: "Разнорабочий",
        city: "Hajnówka (60 км от Белостока)",
        id: 21,
        img: img21,
        salary: [
            "21 зл/ч",
            "от 5000 зл/месяц",
            "Возможна выплата аванса(единоразово)",
            "Зарплата с 10 по 15 число каждого месяца без задержек"
        ],
        factory: "производство мебели",
        clothes: "Одежда и инструменты выдаются",
        schedule: [
            "Две смены: 6.00-18.00, 18.00-6.00",
            "10-12 часов в день",
            "6 дней в неделю (пн-сб)",
            "От 280 часов месяц"
        ],
        who: "пары/женщины/мужчины",
        countPerson: "10 человек",
        housing: [
            "Проживание - 300 зл",
            "Есть отдельные комнаты для пар",
            "Комфортные условия."
        ],
        transport: "До работы можно добраться пешком",
        responsibilities: [
            "Контроль качества",
            "Упаковка заказов",
            "Комплектация"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Предоставление одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "Пары/женщины/мужчины до 55 лет",
        ],
        video: "https://www.youtube.com/embed/WZRECFmOj5k",
        status: "active",
        cityLng: "23.58165",
        cityLat: "52.74514",
        cityInfo: "Хайнувка — город в Польше, входит в Подляское воеводство. Занимает площадь 21,29 км². Население — 22 091 человек (на 2011 год). Город расположен в Бельской равнине, на окраине заповедника Беловежская Пуща. Благодаря своему местоположению Хайнувку называют «воротами в Беловежскую Пущу». Также под Хайнувкой находятся заповедные «Гурнянские луга» и музей узкоколейной железной дороги, который предлагает поездку на старинном паровозе. Во всех заповедниках имеются смотровые площадки, откуда открываются захватывающие дух виды.",
        factoryInfo: "FORTE - один из крупнейших европейских производителей мебели, осуществляющий продажи в более чем 45 стран мира.. В настоящее время FORTE имеет пять современных производственных предприятий, расположенных в городах Острув-Мазовецки, Сувалки, Белосток и Хайнувка. Общая производственная площадь заводов составляет 160 000 м², а площадь складских помещений - 112 000 м². Ассортимент продукции адаптирован к потребностям рынка. Ежегодно в предложение вводится более 800 новых дизайнов.",
    },
    // {
    //     name: "Оператор производственного оборудования",
    //     city: "Miętne (55 км от Варшавы)",
    //     id: 32,
    //     img: img15,
    //     salary: [
    //         "24 зл/ч",
    //         "от 6000 зл/месяц",
    //         "Возможна выплата аванса (единоразово)",
    //         "Зарплата от 10 по 15 число каждого месяца без задержек"
    //     ],
    //     who: "Мужчины",
    //     countPerson: "10 человек",
    //     factory: "производство металлических ворот и ограждений",
    //     schedule: [
    //         "2 смены: 06.00 – 18.00, 18.00 - 6.00",
    //         "5-6 дней в неделю",
    //         "12 часов в день",
    //     ],
    //     housing: [
    //         "Бесплатное проживание",
    //         "Комфортные условия, по 2-4 человека в комнате."
    //     ],
    //     responsibilities: [
    //         "обслуживание производственного оборудования: сварочных станков, пил, профилировочных станков и т.д.",
    //     ],
    //     offers: [
    //         "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
    //         "Бесплатное проживание",
    //         "Транспорт организован (300 зл)",
    //         "Предоставление рабочей одежды и инструментов",
    //         "Прохождение Техники безопасности и охраны труда(BHP)",
    //         "Государственное страхование",
    //         "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
    //         "Помощь координаторов на всех этапах трудоуствойства и пребывания"
    //     ],
    //     requirements: [
    //         "Желание обучаться",
    //         "Польский на базовом уровне"
    //     ],
    //     transport: "Транспорт организован (300 зл)",
    //     video: "https://www.youtube.com/embed/ZSClcZBTOgk",
    //     status: "temporaryNotActive",
    //     cityLng: "21.570331052",
    //     cityLat: "51.920162986",
    //     houseImages: [
    //         "https://i.ibb.co/k9WZFCn/9.jpg",
    //         "https://i.ibb.co/chjMZrj/10.jpg",
    //         "https://i.ibb.co/YN842xH/11.jpg",
    //         "https://i.ibb.co/261ssbf/12.jpg",
    //         "https://i.ibb.co/pnLDvVR/1.jpg",
    //         "https://i.ibb.co/qjr82HD/2.jpg",
    //         "https://i.ibb.co/BCmLBqh/3.jpg",
    //         "https://i.ibb.co/H7DRvf0/4.jpg",
    //         "https://i.ibb.co/wWdxkHw/5.jpg",
    //         "https://i.ibb.co/K9XKG0k/6.jpg",
    //         "https://i.ibb.co/74T7qcf/7.jpg",
    //         "https://i.ibb.co/XstxCNm/8.jpg"
    //     ],
    //     cityInfo: "Ментне - это деревня в Мазовецком воеводстве, в восточно-центральной части Польши. Она расположена примерно в 52 км к юго-востоку от столицы Польши Варшавы. Население деревни составляет около 2000 человек.",
    //     factoryInfo: "Polargos занимается производством ворот и ограждений для жилых и промышленных помещений. Продукция компании доступна в крупнейших строительных и промышленных сетях в Польше и за рубежом. К ним относятся: Castorama, OBI, Leroy Merlin, PSB, Baumax и многие другие. Завод производит классические, современные, панельные заборы, сетчатые ограждения, детали для них и др."
    // },
    // {
    //     name: "Работник на производство деревянных паллет",
    //     city: "Sosnówiec (55 км от Быдгоща)",
    //     id: 26,
    //     img: img26,
    //     salary: [
    //         "17 зл/ч",
    //         "Возможна выплата аванса(единоразово)",
    //         "Зарплата с 10 по 15 число каждого месяца без задержек"
    //     ],
    //     factory: "производство деревянных паллет",
    //     clothes: "Одежда и инструменты выдаются",
    //     schedule: [
    //         "Только дневные смены",
    //         "12-14 часов в день",
    //         "c 6.00 - 16.00, после чего можно остаться на отработку дополнительных часов",
    //         "6-7 дней в неделю",
    //     ],
    //     who: "мужчины",
    //     countPerson: "10 человек",
    //     housing: [
    //         "Жилье бесплатное",
    //         "Комфортные условия."
    //     ],
    //     transport: "До работы можно добраться пешком (~1км)",
    //     responsibilities: [
    //         "Сбивание деревянных паллет",
    //         "Сбивание досок, шлифовка досок, поставка компонентов для производства, прием готовой продукции, монтаж элементов",
    //         "Работа с пневматическим пистолетом",
    //         "Упаковка готовой продукции"
    //     ],
    //     offers: [
    //         "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
    //         "Бесплатное проживание в комфортных условиях (есть фото ниже)",
    //         "Предоставление одежды и инструментов",
    //         "Прохождение Техники безопасности и охраны труда(BHP)",
    //         "Государственное страхование",
    //         "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
    //         "Помощь координаторов на всех этапах трудоуствойства и пребывания"
    //     ],
    //     requirements: [
    //         "Мужчины до 55 лет",
    //     ],
    //     houseImages: [
    //         "https://i.ibb.co/8KKG1X8/IMG-1739.jpg",
    //         "https://i.ibb.co/HX0Kgn0/IMG-1738.jpg",
    //         "https://i.ibb.co/s6fPckD/IMG-1747.jpg",
    //         "https://i.ibb.co/pnZHYRp/IMG-1756.jpg",
    //         "https://i.ibb.co/9H06b4T/IMG-1755.jpg",
    //         "https://i.ibb.co/pLSpznQ/IMG-1746.jpg",
    //         "https://i.ibb.co/NnJtqwY/IMG-1767.jpg"
    //     ],
    //     video: "https://www.youtube.com/embed/cjvc6MqAqP4",
    //     status: "active",
    //     cityLng: "18.025556",
    //     cityLat: "52.690278",
    //     cityInfo: "Сосновец — деревня расположенный в центральной части Польши в Куявско-Поморском воеводстве, в 55 км от Быдгоща или в 90 км от Познаня.",
    //     factoryInfo: "Agra Wood Polska специализируется на производстве деревянных паллет (поддонов), деревянных ящиков, профилей и другой продукции из дерева для строительной, химической, бумажной и пищевой промышленности.",
    // },
    {
        name: "Работник производства металлических ограждений",
        city: "Miętne (55 км от Варшавы)",
        id: 7,
        img: img7,
        salary: [
            "19 зл/ч",
            "от 5000 зл/месяц",
            "Возможна выплата аванса (единоразово)",
            "Зарплата от 10 по 15 число каждого месяца без задержек"
        ],
        housing: [
            "Бесплатное проживание",
            "Комфортные условия."
        ],
        who: "Мужчины/женщины/пары",
        countPerson: "20 человек",
        factory: "изготовление металлических ворот и ограждений",
        schedule: [
            "Две смены: 06.00 – 18.00 и 18.00- 6.00",
            "12 часов в день",
            "5-6 дней в неделю",
            "От 280 часов месяц"
        ],
        transport: "Транспорт организован: 300 зл/месяц",
        responsibilities: [
            "Участие в разных процессах изготовления ворот и ограждений(покраска, упаковка, подготовка к отправке и др.)",
            "Работа на производстве и складах"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Бесплатное проживание",
            "Организация транспорта: 300 зл/месяц",
            "Предоставление рабочей одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "Мужчины/женщины/пары",
        ],
        video: "https://www.youtube.com/embed/ZSClcZBTOgk",
        status: "temporaryNotActive",
        cityLng: "21.570331052",
        cityLat: "51.920162986",
        houseImages: [
            "https://i.ibb.co/k9WZFCn/9.jpg",
            "https://i.ibb.co/chjMZrj/10.jpg",
            "https://i.ibb.co/YN842xH/11.jpg",
            "https://i.ibb.co/261ssbf/12.jpg",
            "https://i.ibb.co/pnLDvVR/1.jpg",
            "https://i.ibb.co/qjr82HD/2.jpg",
            "https://i.ibb.co/BCmLBqh/3.jpg",
            "https://i.ibb.co/H7DRvf0/4.jpg",
            "https://i.ibb.co/wWdxkHw/5.jpg",
            "https://i.ibb.co/K9XKG0k/6.jpg",
            "https://i.ibb.co/74T7qcf/7.jpg",
            "https://i.ibb.co/XstxCNm/8.jpg"
        ],
        cityInfo: "Ментне - это деревня в Мазовецком воеводстве, в восточно-центральной части Польши. Она расположена примерно в 52 км к юго-востоку от столицы Польши Варшавы. Население деревни составляет около 2000 человек.",
        factoryInfo: "Polargos занимается производством ворот и ограждений для жилых и промышленных помещений. Продукция компании доступна в крупнейших строительных и промышленных сетях в Польше и за рубежом. К ним относятся: Castorama, OBI, Leroy Merlin, PSB, Baumax и многие другие. Завод производит классические, современные, панельные заборы, сетчатые ограждения, детали для них и др."
    },
    {
        name: "Сварщик MIG-MAG",
        city: "Miętne (55 км от Варшавы)",
        id: 1,
        img: img1,
        salary: [
            "20-24 зл/ч (в зависимости от умений)+премии",
            "от 6000 зл/месяц",
            "20 зл/час - начальный уровень без опыта, если будет сдан тест",
            "22 зл/час - средний уровень, есть минимальный опыт, хорошее качество работы",
            "24 зл/час - высокий уровень, большой опыт, очень хорошее качество работы",
            "Возможна выплата аванса(единоразово)",
            "Зарплата с 10 по 15 число каждого месяца без задержек"
        ],
        factory: "изготовление металлических ворот и ограждений",
        schedule: [
            "Две смены: 06.00 – 18.00 и 18.00- 6.00",
            "10-12 часов в день",
            "От 280 часов месяц"
        ],
        who: "Мужчины",
        countPerson: "7 мужчин",
        housing: [
            "Бесплатное проживание",
            "Трехэтажный дом",
            "Комфортные условия. На каждом этаже есть кухня, туалет и душ."
        ],
        transport: "Транспорт организован: 200 зл/месяц",
        responsibilities: [
            "Сварка разных металлов и цинка",
            "Наплавление дефектов оборудования, пайка деталей"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Бесплатное проживание",
            "Организация транспорта: 200 зл/месяц",
            "Предоставление рабочей одежды и инструментов",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "Только мужчины",
            "Опыт работы со варкой MIG-MAG"
        ],
        video: "https://www.youtube.com/embed/ZSClcZBTOgk",
        status: "temporaryNotActive",
        cityLng: "21.570331052",
        cityLat: "51.920162986",
        houseImages: [
            "https://i.ibb.co/k9WZFCn/9.jpg",
            "https://i.ibb.co/chjMZrj/10.jpg",
            "https://i.ibb.co/YN842xH/11.jpg",
            "https://i.ibb.co/261ssbf/12.jpg",
            "https://i.ibb.co/pnLDvVR/1.jpg",
            "https://i.ibb.co/qjr82HD/2.jpg",
            "https://i.ibb.co/BCmLBqh/3.jpg",
            "https://i.ibb.co/H7DRvf0/4.jpg",
            "https://i.ibb.co/wWdxkHw/5.jpg",
            "https://i.ibb.co/K9XKG0k/6.jpg",
            "https://i.ibb.co/74T7qcf/7.jpg",
            "https://i.ibb.co/XstxCNm/8.jpg"
        ],
        cityInfo: "Ментне - это деревня в Мазовецком воеводстве, в восточно-центральной части Польши. Она расположена примерно в 52 км к юго-востоку от столицы Польши Варшавы. Население деревни составляет около 2000 человек.",
        factoryInfo: "Polargos занимается производством ворот и ограждений для жилых и промышленных помещений. Продукция компании доступна в крупнейших строительных и промышленных сетях в Польше и за рубежом. К ним относятся: Castorama, OBI, Leroy Merlin, PSB, Baumax и многие другие. Завод производит классические, современные, панельные заборы, сетчатые ограждения, детали для них и др."
    },
    // {
    //     name: "Работник на стекольное производство",
    //     city: "Barczewo (20 км от Ольштына)",
    //     id: 24,
    //     img: img24,
    //     salary: [
    //         "21 зл/ч",
    //         "+ премия за посещаемость ок. 120 зл",
    //         "от 5000 зл/месяц",
    //         "Возможна выплата аванса (единоразово)",
    //         "Зарплата от 10 по 15 число каждого месяца без задержек"
    //     ],
    //     who: "Мужчины",
    //     countPerson: "10 мужчин",
    //     factory: "производство стеклопакетов",
    //     schedule: [
    //         "три смены: 06.00 – 14.00, 14.00 - 22.00, 22:00 - 6:00",
    //         "5-6 дней в неделю",
    //         "8-12 часов в день",
    //         "пока проходит обучение работа по 8 часов (неделя-две), а потом уже работа по 10-12 часов в день",
    //         "200-220 часов в месяц"
    //     ],
    //     housing: [
    //         "Бесплатное проживание",
    //         "Комфортные условия."
    //     ],
    //     responsibilities: [
    //         "Производство изоляционного стекла",
    //         "Нарезка, мойка, сортировка стекла",
    //         "Изготовление рамок",
    //         "Подготовка к отправке т.д."
    //     ],
    //     offers: [
    //         "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
    //         "Бесплатное проживание",
    //         "До работы можно добраться пешком",
    //         "Предоставление рабочей одежды и инструментов",
    //         "Помощь в получении прав UDT",
    //         "Прохождение Техники безопасности и охраны труда(BHP)",
    //         "Государственное страхование",
    //         "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
    //         "Помощь координаторов на всех этапах трудоуствойства и пребывания"
    //     ],
    //     requirements: [
    //         "Мужчины в возрасте от 20 до 50 лет",
    //         "Знание русского языка обязательно",
    //         "Желание более длительного сотрудничества",
    //     ],
    //     houseImages: [
    //         "https://i.ibb.co/ZNw8Fhb/viber-2022-05-17-13-17-14-049.jpg",
    //         "https://i.ibb.co/zQ40BSL/viber-2022-05-11-12-32-04-762.jpg",
    //         "https://i.ibb.co/WnSkBG2/viber-2022-05-11-12-32-05-785.jpg",
    //         "https://i.ibb.co/vzQpBrK/viber-2022-05-11-12-32-06-648.jpg",
    //         "https://i.ibb.co/93s16tL/viber-2022-05-11-12-32-07-387.jpg",
    //         "https://i.ibb.co/7Ky8sWX/viber-2022-05-11-12-32-03-902.jpg"
    //     ],
    //     transport: "До работы можно добраться пешком",
    //     video: "https://www.youtube.com/embed/Z8-aocgVLCQ",
    //     status: "temporaryNotActive",
    //     cityLng: "20.69112",
    //     cityLat: "53.83055",
    //     cityInfo: "Барчево — город в Польше, входит в Варминьско-Мазурское воеводство, находится в 20 км от Ольштына. Занимает площадь 4,58 км². Население — 7472 человека. Территория города богата лесами многочисленными природными и ландшафтными ценностями, способствующими развитию туризма, в том числе агротуризма.",
    //     factoryInfo: "Glassolutions является частью группы Saint-Gobain и благодаря многолетнему опыту является ведущим поставщиком решений высочайшего качества и самых инновационных изделий из стекла для разного вида помещений, строительства, окон и дверей."
    // },
    // {
    //     name: "Работник на производство садовой архитектуры",
    //     city: "Przejęsław (16 км от Болеславца)",
    //     id: 22,
    //     img: img22,
    //     salary: [
    //         "19 зл/ч",
    //         // "+ премия до 400 зл (за эффективность, отсутствие пропусков и соблюдение правил техники безопасности)",
    //         "Возможна выплата аванса(единоразово)",
    //         "Зарплата с 10 по 15 число каждого месяца без задержек"
    //     ],
    //     factory: "производство деревянной садовой архитектуры",
    //     clothes: "Одежда и инструменты выдаются",
    //     schedule: [
    //         "3 смены",
    //         "8 часов в день",
    //         "есть возможность иногда оставаться на смену 12 часов",
    //         "5-6 дней в неделю (пн-сб)",
    //     ],
    //     who: "мужчины",
    //     countPerson: "10 человек",
    //     housing: [
    //         "Бесплатное проживание",
    //         "Комфортные условия."
    //     ],
    //     transport: "Транспорт организован (300 зл/месяц)",
    //     responsibilities: [
    //         "Простые работы связанные с изготовлением деревянной садовой архитектуры",
    //         "Сбивание досок, шлифовка досок, поставка компонентов для производства, прием готовой продукции, монтаж элементов",
    //         "Работа с пневматическим пистолетом",
    //         "Упаковка готовых продуктов, маркировка"
    //     ],
    //     offers: [
    //         "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
    //         "Бесплатное проживание",
    //         "Транспорт организован (300 зл/месяц)",
    //         "Предоставление одежды и инструментов",
    //         "Прохождение Техники безопасности и охраны труда(BHP)",
    //         "Государственное страхование",
    //         "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
    //         "Помощь координаторов на всех этапах трудоуствойства и пребывания"
    //     ],
    //     requirements: [
    //         "Только мужчины до 50 лет",
    //     ],
    //     houseImages: [
    //         "https://i.ibb.co/b7WhTR5/image-1.webp",
    //         "https://i.ibb.co/wrNW752/image-2.webp",
    //         "https://i.ibb.co/0qK6y2G/image-3.webp",
    //         "https://i.ibb.co/TvtbqYG/image-4.webp",
    //         "https://i.ibb.co/6XCmdsF/image.webp"
    //     ],
    //     video: "https://www.youtube.com/embed/fgT6hR5bS04",
    //     status: "temporaryNotActive",
    //     cityLng: "15.4202",
    //     cityLat: "51.3637",
    //     cityInfo: "Przejęsław (Пшееслав) - деревня расположенная примерно в 16 км к северо-западу от Болеславца и в 117 км к западу от областной столицы Вроцлава.",
    //     factoryInfo: "FOREST STYLE GROUP — специализируется на производстве деревянной садовой архитектуры с 1999 года и на сегодняшний день является европейским лидером в области наружной отделки из обработанной древесины. Компания предлагает широкий выбор готовой продукции: садовая мебель,  заборы, терассы, навесы и т.д.",
    // },
    // {
    // {
    //     name: "Работник на мебельную фабрику",
    //     city: "Potulice (22 км от Быдгоща)",
    //     id: 11,
    //     img: img11,
    //     salary: [
    //         "16 зл/ч",
    //         "от 4000 зл/месяц",
    //         "премия до 1200 зл/месяц",
    //         "Возможна выплата аванса (единоразово)",
    //         "Зарплата от 10 по 15 число каждого месяца без задержек"
    //     ],
    //     factory: "производство мебели",
    //     clothes: "Одежда и инструменты выдаются",
    //     who: "Мужчины",
    //     countPerson: "10 мужчин",
    //     schedule: [
    //         "2 смены: 06.00 – 18.00, 18.00-06.00",
    //         "10-12 часов в день",
    //         "5-6 дней в неделю",
    //         "От 280 часов месяц"
    //     ],
    //     housing: [
    //         "Бесплатное проживание",
    //         "Комфортные условия."
    //     ],
    //     responsibilities: [
    //         "Производство мебельных конструкций и мебели(кухонная мебель, шкафы и др.)",
    //     ],
    //     offers: [
    //         "Только официальное трудоустройство по Umowie Zlecenia(договор подряда)",
    //         "Бесплатное проживание",
    //         "До работы можно добраться пешком",
    //         "Предоставление рабочей одежды и инструментов",
    //         "Прохождение Техники безопасности и охраны труда(BHP)",
    //         "Государственное страхование",
    //         "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
    //         "Помощь координаторов на всех этапах трудоуствойства и пребывания"
    //     ],
    //     requirements: [
    //         "Только мужчины",
    //     ],
    //     transport: "До работы можно добраться пешком",
    //     cityLng: "17.16916599",
    //     cityLat: "53.458831498",
    //     video: "https://www.youtube.com/embed/WZRECFmOj5k",
    //     status: "temporaryNotActive",
    //     cityInfo: "Потулице - деревня в на севере центральной части Польши. Она находится примерно в 7 км к юго-востоку от Накло-над-Нотечем и в 22 км к западу от Быдгоща. По состоянию на 2011 год численность населения составляет 1477 человек. На территории деревни можно посетить Дворцово-парковый комплекс в Потулице - построен в 19 веке для Казимира Потулицкого.",
    //     factoryInfo: "Hjort Knudsen - проектирует, производит и продает мебель клиентам по всей Европе. Компания имеет репутацию в мебельной промышленности как лидера инноваций. Hjort Knudsen производит множество видов продукции, от матрасов до диванов."
    // },
    // {
    //     name: "Водитель погрузчика",
    //     city: "Hajnówka (60 км от Белостока)",
    //     id: 19,
    //     img: img19,
    //     salary: [
    //         "18 зл/час",
    //         "от 4200 зл/месяц",
    //         "Возможна выплата аванса(единоразово)",
    //         "Зарплата с 10 по 15 число каждого месяца без задержек"
    //     ],
    //     factory: "производство мебели",
    //     clothes: "Одежда и инструменты выдаются",
    //     schedule: [
    //         "Две смены: 6.00-18.00, 18.00-6.00",
    //         "10-12 часов в день",
    //         "6 дней в неделю (пн-сб)",
    //         "От 280 часов месяц"
    //     ],
    //     who: "Мужчины",
    //     countPerson: "11 мужчин",
    //     housing: [
    //         "Бесплатное проживание",
    //         "Комфортные условия."
    //     ],
    //     transport: "Транспорт организован (250 зл/месяц)",
    //     responsibilities: [
    //         "Работа на грузоподъемнике",
    //         "Комплектация заказов",
    //     ],
    //     offers: [
    //         "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
    //         "Бесплатное проживание",
    //         "Транспорт организован (250 зл/месяц)",
    //         "Предоставление одежды и инструментов",
    //         "Прохождение Техники безопасности и охраны труда(BHP)",
    //         "Государственное страхование",
    //         "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
    //         "Помощь координаторов на всех этапах трудоуствойства и пребывания"
    //     ],
    //     requirements: [
    //         "Только мужчины",
    //         "Опыт не требуется",
    //         "Если у Вас нет прав UDT, то мы организовываем 3-х днеынве курсы для их получения"
    //     ],
    //     houseImages: [
    //         "https://i.ibb.co/5kgdPhN/photo-2022-02-09-08-46-53.jpg",
    //         "https://i.ibb.co/xgCV1cs/photo-2022-02-09-08-46-48.jpg",
    //         "https://i.ibb.co/KKdhPx2/photo-2022-02-09-08-46-50.jpg",
    //         "https://i.ibb.co/pxSnXJX/photo-2022-02-09-08-46-51.jpg"
    //     ],
    //     video: "https://www.youtube.com/embed/WZRECFmOj5k",
    //     status: "active",
    //     cityLng: "23.16433",
    //     cityLat: "53.13333",
    //     cityInfo: "Белосто́к (Białystok) — город на северо-востоке Польши, на реке Бяла. Административный центр Подляского воеводства. Население на 31 декабря 2019 года составляло 297 554 человек, город является одиннадцатым в Польше по величине.",
    //     factoryInfo: "FORTE - один из крупнейших европейских производителей мебели, осуществляющий продажи в более чем 45 стран мира.. В настоящее время FORTE имеет пять современных производственных предприятий, расположенных в городах Острув-Мазовецки, Сувалки, Белосток и Хайнувка. Общая производственная площадь заводов составляет 160 000 м², а площадь складских помещений - 112 000 м². Ассортимент продукции адаптирован к потребностям рынка. Ежегодно в предложение вводится более 800 новых дизайнов.",
    // },
    {
        name: "Работник на печатном производстве",
        city: "Koszalin",
        id: 14,
        img: img14,
        salary: [
            "20.50 - 21 зл/ч",
            "от 5000 зл/месяц",
            "Возможна выплата аванса(единоразово)",
            "Зарплата с 10 по 15 число каждого месяца без задержек"
        ],
        factory: "производство цифровой печати",
        clothes: "Инструменты выдаются, одежда своя",
        schedule: [
            "2 смены: 06.00 – 18.00, 18.00-06.00",
            "12 часов в день",
            "6 дней в неделю (пн-сб)",
            "От 280 часов месяц"
        ],
        who: "Мужчины",
        countPerson: "7 мужчин",
        housing: [
            "Бесплатное проживание в городе Mielno",
            "Комфортные условия."
        ],
        transport: "Доезд самостоятельный",
        responsibilities: [
            "Упаковка флагов, баннеров, палет, выставочных систем",
            "Нарезка флагов, баннеров и т.д.",
            "Изготовление дырок на продукции",
            "Обслуживание оборудования"
        ],
        offers: [
            "Только официальное трудоустройство по Umowie Zlecenia (договор подряда)",
            "Бесплатное проживание в городе Mielno",
            "Предоставление инструментов (одежда своя)",
            "Прохождение Техники безопасности и охраны труда(BHP)",
            "Государственное страхование",
            "Помощь в оформлении документов для продления пребывания в Польше (Карта побыта, Воеводское приглашение и т.д.)",
            "Помощь координаторов на всех этапах трудоуствойства и пребывания"
        ],
        requirements: [
            "Мужчины/женщины/пары до 45 лет",
            "Желательно знание польского языка, понимание польского обязательно",
        ],
        houseImages: [
            "https://i.ibb.co/LSdNFXZ/image-3.jpg",
            "https://i.ibb.co/hChkc1b/image.jpg",
            "https://i.ibb.co/vcFS4m5/image-4.jpg",
            "https://i.ibb.co/3WY811k/image-2.jpg",
            "https://i.ibb.co/hH0GQM1/image-1.jpg"
        ],
        video: "https://www.youtube.com/embed/uYS1urYp-C0",
        status: "active",
        cityLng: "16.1714908",
        cityLat: "54.1943219",
        cityInfo: "Коша́лин — город в Польше, в Западно-Поморском воеводстве. Расположен в 5 км от побережья Балтийского моря в северо-западной Польше, второй по населению в Западно-поморском воеводстве. Город является крупным культурным центром в регионе. В городе есть театры, кинотеатры, концертный зал, амфитеатр, музеи, аквапарк, также развитая сеть спортивных центров. В городе часто проходят фестивали народного творчества. Город имеет собственные средства массовой информации и ряд высших учебных заведений. Кошалин является одним из крупнейших центров хозяйствования на Поморье.",
        factoryInfo: "FF Linea - производитель цифровой печати с более чем 20-летним опытом работы на рынке ЕС. Производство может предложить широкий ассортимент продукции высочайшего качества, в т.ч. флаги, баннеры, пляжные флаги, выставочные системы. Благодаря экологически чистым и современным технологиям, высококвалифицированному персоналу из более чем 120 специалистов и энтузиазму, вложенному в работу, FF Linea является одним из лидеров рекламной индустрии в Европе."
    },
];



